define("discourse/plugins/automation/discourse/components/fields/da-period-field", ["exports", "@glimmer/tracking", "@ember/component", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/runloop", "@ember-compat/tracked-built-ins", "I18n", "select-kit/components/combo-box", "discourse/plugins/automation/discourse/components/fields/da-base-field", "discourse/plugins/automation/discourse/components/fields/da-field-description", "discourse/plugins/automation/discourse/components/fields/da-field-label", "@ember/template-factory"], function (_exports, _tracking, _component, _helper, _modifier, _object, _runloop, _trackedBuiltIns, _I18n, _comboBox, _daBaseField, _daFieldDescription, _daFieldLabel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _PeriodField;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let PeriodField = _exports.default = (_class = (_PeriodField = class PeriodField extends _daBaseField.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "interval", _descriptor, this);
      _initializerDefineProperty(this, "frequency", _descriptor2, this);
      (0, _runloop.next)(() => {
        if (!this.args.field.metadata.value) {
          this.args.field.metadata.value = new _trackedBuiltIns.TrackedObject({
            interval: 1,
            frequency: null
          });
        }
        this.interval = this.args.field.metadata.value.interval;
        this.frequency = this.args.field.metadata.value.frequency;
      });
    }
    get recurringLabel() {
      return _I18n.default.t("discourse_automation.triggerables.recurring.every");
    }
    get replacedContent() {
      return (this.args.field?.extra?.content || []).map(r1 => {
        return {
          id: r1.id,
          name: _I18n.default.t(r1.name)
        };
      });
    }
    mutInterval(event1) {
      this.args.field.metadata.value.interval = event1.target.value;
    }
    mutFrequency(value1) {
      this.args.field.metadata.value.frequency = value1;
      this.frequency = value1;
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="field period-field control-group">
        <DAFieldLabel @label={{@label}} @field={{@field}} />
  
        <div class="controls">
          {{this.recurringLabel}}
  
          <Input
            @type="number"
            defaultValue="1"
            @value={{this.interval}}
            disabled={{@field.isDisabled}}
            required={{@field.isRequired}}
            {{on "input" this.mutInterval}}
          />
  
          <ComboBox
            @value={{this.frequency}}
            @content={{this.replacedContent}}
            @onChange={{this.mutFrequency}}
            @options={{hash allowAny=false disabled=@field.isDisabled}}
            @required={{@field.isRequired}}
          />
  
          <DAFieldDescription @description={{@description}} />
        </div>
      </div>
    
  */
  {
    "id": "qZ9msRvJ",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"field period-field control-group\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[1,[30,0,[\"recurringLabel\"]]],[1,\"\\n\\n        \"],[8,[32,1],[[24,\"defaultValue\",\"1\"],[16,\"disabled\",[30,2,[\"isDisabled\"]]],[16,\"required\",[30,2,[\"isRequired\"]]],[4,[32,2],[\"input\",[30,0,[\"mutInterval\"]]],null]],[[\"@type\",\"@value\"],[\"number\",[30,0,[\"interval\"]]]],null],[1,\"\\n\\n        \"],[8,[32,3],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\",\"@required\"],[[30,0,[\"frequency\"]],[30,0,[\"replacedContent\"]],[30,0,[\"mutFrequency\"]],[28,[32,4],null,[[\"allowAny\",\"disabled\"],[false,[30,2,[\"isDisabled\"]]]]],[30,2,[\"isRequired\"]]]],null],[1,\"\\n\\n        \"],[8,[32,5],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/discourse/components/fields/da-period-field.js",
    "scope": () => [_daFieldLabel.default, _component.Input, _modifier.on, _comboBox.default, _helper.hash, _daFieldDescription.default],
    "isStrictMode": true
  }), _PeriodField), _PeriodField), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "interval", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "frequency", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "mutInterval", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mutInterval"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mutFrequency", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mutFrequency"), _class.prototype)), _class);
});