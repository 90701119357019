define("discourse/plugins/automation/discourse/admin/adapters/discourse-automation-adapter", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Adapter extends _rest.default {
    basePath() {
      return "/admin/plugins/discourse-automation/";
    }
    pathFor() {
      return super.pathFor(...arguments).replace("_", "-") + ".json";
    }
  }
  _exports.default = Adapter;
});