define("discourse/plugins/automation/discourse/components/fields/da-base-field", ["exports", "@glimmer/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let BaseField = _exports.default = (_class = class BaseField extends _component.default {
    get displayPlaceholders() {
      return this.args.placeholders?.length && this.args.field?.acceptsPlaceholders;
    }
    mutValue(newValue) {
      this.args.field.metadata.value = newValue;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "mutValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mutValue"), _class.prototype)), _class);
});